.chat-tool { 
    cursor: pointer;
    margin-top: 25px;
    display: flex;
    align-items: center;
    gap: 15px;
}

.chat-tool svg {
    margin-top: -3px !important;
}

.chat-tool-title {
    color: #474747;
    font-family: "Inter400";
    font-size: 13px;
}

@media (max-width: 1400px) {
    .chat-tool-title {
        font-size: 13px;
    }
}

@media (max-width: 1200px) {
    
}
