:root {
  --main-color: #920770;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
  margin-bottom: 0;
}

a {
  text-decoration: none !important;
}

ul {
  list-style: none;
}
body {
  font-family:'Poppins400';
  max-width: 100%;
  overflow-x: hidden;
}

/* Start Fonts */
/* Poppins */
@font-face {
  font-family: "Poppins100";
  src: url(./assets/fonts/Poppins/Poppins-Thin.ttf);
}
@font-face {
  font-family: "PoppinsItalic100";
  src: url(./assets/fonts/Poppins/Poppins-ThinItalic.ttf);
}
@font-face {
  font-family: "Poppins200";
  src: url(./assets/fonts/Poppins/Poppins-ExtraLight.ttf);
}
@font-face {
  font-family: "PoppinsItalic200";
  src: url(./assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf);
}
@font-face {
  font-family: "Poppins300";
  src: url(./assets/fonts/Poppins/Poppins-Light.ttf);
}
@font-face {
  font-family: "PoppinsItalic300";
  src: url(./assets/fonts/Poppins/Poppins-LightItalic.ttf);
}
@font-face {
  font-family: "Poppins400";
  src: url(./assets/fonts/Poppins/Poppins-Regular.ttf);
}
@font-face {
  font-family: "PoppinsItalic400";
  src: url(./assets/fonts/Poppins/Poppins-Italic.ttf);
}
@font-face {
  font-family: "Poppins500";
  src: url(./assets/fonts/Poppins/Poppins-Medium.ttf);
}
@font-face {
  font-family: "PoppinsItalic500";
  src: url(./assets/fonts/Poppins/Poppins-MediumItalic.ttf);
}
@font-face {
  font-family: "Poppins600";
  src: url(./assets/fonts/Poppins/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: "PoppinsItalic600";
  src: url(./assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf);
}
@font-face {
  font-family: "Poppins700";
  src: url(./assets/fonts/Poppins/Poppins-Bold.ttf);
}
@font-face {
  font-family: "PoppinsItalic700";
  src: url(./assets/fonts/Poppins/Poppins-BoldItalic.ttf);
}
@font-face {
  font-family: "Poppins800";
  src: url(./assets/fonts/Poppins/Poppins-ExtraBold.ttf);
}
@font-face {
  font-family: "PoppinsItalic800";
  src: url(./assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf);
}
/* End Poppins */

/* Dm Sans */
@font-face {
  font-family: "Dmsans100";
  src: url(./assets/fonts/DmSans/DMSans-Thin.ttf);
}
@font-face {
  font-family: "DmsansItalic100";
  src: url(./assets/fonts/DmSans/DMSans-ThinItalic.ttf);
}
@font-face {
  font-family: "Dmsans200";
  src: url(./assets/fonts/DmSans/DMSans-ExtraLight.ttf);
}
@font-face {
  font-family: "DmsansItalic200";
  src: url(./assets/fonts/DmSans/DMSans-ExtraLightItalic.ttf);
}
@font-face {
  font-family: "Dmsans300";
  src: url(./assets/fonts/DmSans/DMSans-Light.ttf);
}
@font-face {
  font-family: "DmsansItalic300";
  src: url(./assets/fonts/DmSans/DMSans-LightItalic.ttf);
}
@font-face {
  font-family: "Dmsans400";
  src: url(./assets/fonts/DmSans/DMSans-Regular.ttf);
}
@font-face {
  font-family: "DmsansItalic400";
  src: url(./assets/fonts/DmSans/DMSans-Italic.ttf);
}
@font-face {
  font-family: "Dmsans500";
  src: url(./assets/fonts/DmSans/DMSans-Medium.ttf);
}
@font-face {
  font-family: "DmsansItalic500";
  src: url(./assets/fonts/DmSans/DMSans-MediumItalic.ttf);
}
@font-face {
  font-family: "Dmsans600";
  src: url(./assets/fonts/DmSans/DMSans-SemiBold.ttf);
}
@font-face {
  font-family: "DmsansItalic600";
  src: url(./assets/fonts/DmSans/DMSans-SemiBoldItalic.ttf);
}
@font-face {
  font-family: "Dmsans700";
  src: url(./assets/fonts/DmSans/DMSans-Bold.ttf);
}
@font-face {
  font-family: "DmsansItalic700";
  src: url(./assets/fonts/DmSans/DMSans-BoldItalic.ttf);
}
@font-face {
  font-family: "Dmsans800";
  src: url(./assets/fonts/DmSans/DMSans-ExtraBold.ttf);
}
@font-face {
  font-family: "DmsansItalic800";
  src: url(./assets/fonts/DmSans/DMSans-ExtraBoldItalic.ttf);
}
/* End Dm Sans */

/* Inter */
@font-face {
  font-family: "Inter100";
  src: url(./assets/fonts/Inter/Inter-Thin.ttf);
}
@font-face {
  font-family: "Inter200";
  src: url(./assets/fonts/Inter/Inter-ExtraLight.ttf);
}
@font-face {
  font-family: "Inter300";
  src: url(./assets/fonts/Inter/Inter-Light.ttf);
}
@font-face {
  font-family: "Inter400";
  src: url(./assets/fonts/Inter/Inter-Regular.ttf);
}
@font-face {
  font-family: "Inter500";
  src: url(./assets/fonts/Inter/Inter-Medium.ttf);
}
@font-face {
  font-family: "Inter600";
  src: url(./assets/fonts/Inter/Inter-SemiBold.ttf);
}
@font-face {
  font-family: "Inter700";
  src: url(./assets/fonts/Inter/Inter-Bold.ttf);
}
@font-face {
  font-family: "Inter800";
  src: url(./assets/fonts/Inter/Inter-ExtraBold.ttf);
}
@font-face {
  font-family: "Inter900";
  src: url(./assets/fonts/Inter/Inter-Black.ttf);
}
/* End Inter */

/* Start Noto-sans-arabic */
@font-face {
  font-family: "NotoSans";
  src: url(./assets/fonts/NotoSansArabic/NotoSansArabic-VariableFont_wdth\,wght.ttf);
}
/* End Noto-sans-arabic */
/* End Fonts */


.form-check-input:focus {
  box-shadow: none !important;
  border: none !important;
}

.popup-error {
  color: #EC5252;
  margin: 0 0 5px !important;
  font-family: "Dmsans300";
  font-size: 12px;
}



.ReactModal__Content--after-open {
  inset: unset !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 350px !important;
  min-height: 153px !important;
  max-height: 214px !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  border-radius: 8px !important;
  border: unset !important;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.14) !important;
  overflow: unset !important;
  padding: unset !important;
}

.ReactModal__Overlay {
  z-index: 200 !important;
}



.react-loading-skeleton {
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block;
  background-color: var(--base-color);
  width: 100%;
  border-radius: 11px !important;
  display: inline-flex;
  line-height: 10px !important;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
  opacity: 0.5 !important;
}

