 /* Start Scroll Style */
 .chatbot-scroll {
    overflow-y: scroll;
}
/* width */
.chatbot-scroll::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.chatbot-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #424242; 
}
   
  /* Handle */
.chatbot-scroll::-webkit-scrollbar-thumb {
    background: #686868; 
    border-radius: 0;
}

.chats::-webkit-scrollbar {
    width: 3px;
}

.chats::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #424242; 
}

.chats::-webkit-scrollbar-thumb {
    background: #686868; 
    border-radius: 0;
}

/* End Scroll Style */



.chatbot-container {
    position: relative;
    width: 100%;
    height: 100vh;
}
.chat-overlay {
    display: none;
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.6));
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 101;
}
.chatbot-background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -2;
}
.chatbot-background-image {
    position: absolute;
    right: -500px;
    top: 0;
    z-index: -1;
}
.chatbot-background-bottom-image {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

.side-chatbot {
    position: sticky;
    height: 96vh;
    width: 250px;
    border-radius: 16px;
    background: #F9F9F9;
    bottom: 13px;
    top: 15px;
}

.side-chatbot-container {
    padding-top: 20px;
    padding-bottom: 10px;
    display: flex;
    width: 90%;
    height: 100%;
    margin: 0 auto;
    flex-direction: column;
    justify-content: space-between;
}

.new-chat {
    width: 100%;
    margin: 0;
    padding: 11px 20px;
    border-radius: 10px;
    background: linear-gradient(92deg, rgba(146, 7, 112, 0.70) -16.64%, rgba(146, 7, 112, 0.80) 117.28%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    color: #fff;
    font-family: "Inter500";
    font-size: 14px;
    border: none;
    outline: none;
    text-align: start;
    margin-top: 40px !important;
}
.new-chat svg {
    margin-top: -3px;
    margin-right: 8px;
    width: 21px;
    height: 21px;
}

.chat-container {
    padding-bottom: 100px;
    margin-top: 20px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 62%;
    margin: 0 auto;
    margin-left: 220px !important;
    flex-direction: column;
}

.chat-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-link {
    display: flex;
    align-items: center;
    gap: 8px;
    transition: .3s ease;
}
.chat-link h1 {
    color: #1F384C;
    font-family: "Poppins400";
    font-size: 16px;
    margin: 0;
}
.chat-link:hover {
    gap: 20px;
}

.chat-theme {
    display: flex;
    align-items: center;
}

.chat-language {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 40px;
    gap: 5px;
}
.chat-language h1 {
    color: #000;
    font-family: "Inter600";
    font-size: 15px;
    margin: 0;
}
.chat-language svg {
    margin-top: -3px;
}
.chat-language::after {
    content: "";
    position: absolute;
    right: -20px;
    height: 93%;
    top: 50%;
    transform: translateY(-50%);
    background: #4B4B4B;
    width: 2px;
}

.theme-switch {
    display: flex;
    align-items: center;
    gap: 8px;
}
.theme-switch .form-switch {
    margin: 0 !important;
}
.theme-switch .form-switch .form-check-input {
    background-color: var(--main-color) !important;
    border: unset;
    cursor: pointer;
}

.chatbot-container .form-switch .form-check-input:focus {
    --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e);
}

.chatbot-desc {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.chat-title {
    margin-top: 40px;
    color: #1C1C1C;
    font-family: "Inter600";
    font-size: 29px;
    letter-spacing: 0.6px;
    text-align: center;
}

.chat-para {
    color: #363636;
    font-family: "Inter300";
    font-size: 16px;
    text-align: center;
    margin: 23px 0 33px !important;
}

.chat-btns {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 11px;
}

.chat-btn {
    padding: 10px 26px;
    border-radius: 5px;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    font-family: "Inter300";
    font-size: 14px;
    background: #fff;
    transition: .3s ease;
}
.chat-btn-active {
    background: linear-gradient(92deg, rgba(146, 7, 112, 0.70) -16.64%, rgba(146, 7, 112, 0.80) 117.28%);
    color: #E5E5E5;
    font-family: "Inter500";
    border: unset;
}
.chat-btn:hover {
    padding: 10px 26px;
    background: var(--main-color);
    color: #E5E5E5;
}

.chat-features {
    margin-top: 200px !important;
}


.chat-input-send-container {
    position: fixed;
    width: 52%;
    bottom: 20px;
}

.chat-input-container {
    width: 100%;
    position: relative;
    padding: 15px 60px 13px 15px;
    border-radius: 10px;
    border: 1px solid rgba(104, 104, 104, 0.30);
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(16px);
}

.chat-input {
    width: 100%;
    padding-right: 30px;
    background: transparent;
    border: none !important;
    outline: none !important;
    font-family: "Inter300";
    font-size: 15px;
    outline: none;
    resize: none;
    box-sizing: border-box;
    transition: height 0.2s ease-in-out;
}
.chat-input::placeholder {
    color: #686868;
    font-family: "Inter400";
    font-size: 16px;
}


.chat-input::-webkit-scrollbar {
    width: 4px; /* Width of the vertical scrollbar */
    right: 20px;
  }
  
  /* Track (background) */
  .chat-input::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light grey background */
    border-radius: 20px; /* Rounded corners for the track */
  }
  
  /* Thumb (the draggable part) */
  .chat-input::-webkit-scrollbar-thumb {
    background: #888; /* Darker grey thumb */
    border-radius: 20px; /* Rounded corners for the thumb */
  }
  
  /* Thumb on hover */
  .chat-input::-webkit-scrollbar-thumb:hover {
    background: #555; /* Even darker grey when hovered */
  }

.chat-input-btn {
    padding: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 10px;
    bottom: 10px;
    border-radius: 10px;
    background: #920770;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    border: unset;
}


.chatbot-sidebar-content {
    height: 78%;
} 

.cahabot-profile {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: 15px;
}

.chatbot-profile-img {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--main-color);
    border-radius: 50%;
    width: 38px;
    height: 38px;
}

.cahabot-profile-name {
    color: #474747;
    font-family: "Inter500";
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 5px;
}

.chatbot-profile-title {
    color: rgba(0, 0, 0, 0.40);
    font-family: "Inter400";
    font-size: 13px;
    margin: 0;
}

.chats {
    padding-top: 20px;
    margin-left: 10px;
    overflow-y: scroll;
    max-height: 82%;
    margin-top: 15px;
    padding-bottom: 14px;
}

.chats-loading {
    height: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chats-loading .css-18lrjg1-MuiCircularProgress-root {
    color: #363636 !important;
    width: 30px !important;
    height: 30px !important;
}

.side-footer-chat {
    padding-top: 32px;
    padding-left: 10px;
    padding-bottom: 15px;
}

.logout-chat {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 30px;
}
.logout-chat img {
    margin-top: -3px !important;
}
.logout-chat-title {
    color: #F55;
    font-family: "Inter400";
    font-size: 13px;
}

.chat-response-para {
    color: #000;
    font-family: "Poppins300";
    font-size: 15px;
    /* word-wrap: break-word; */
    line-height: 24px;
    width: 100%;
}


.chat-message-para {
    color: #000;
    font-family: "Poppins300";
    font-size: 16px;
}

.chat-messages {
    width: 100%;
    max-height: 100%;
    flex: 1;
    padding-left: 15px;
}

.sidebar-chatbot {
    height: 100%;
}

.chat-show-sidebar-btn {
    display: none;
    background: none;
    border: none;
    outline: none;
    margin-right: 15px;
    margin-top: -5px;
}
.chat-hide-sidebar-btn {
    display: none;
    background: none;
    border: none;
    outline: none;
    transform: rotateY(180deg);
    margin-left: 40px;
    margin-top: -5px;
}

.user-message p {
    font-family: "Poppins500";
    font-size: 14px;
    line-height: 25px;
}

.chat-response {
    margin-top: 20px;
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 15px;
    margin-bottom: 40px;
}
.chat-response.user-message {
    padding-bottom: 20px;
    margin-bottom: unset !important;
    border-bottom: 1px solid #ccc;
}

.chats .chat-message:first-child { 
    margin-top: unset !important;
}

.side-footer-chat .chat-tool:first-child {
    margin-top: unset !important;
}

.chatbot-ref {
    position: absolute;
    bottom: 0;
    background: red;
}

.chatbot-toast {
    font-size: 14px !important;
    background: #fff !important;
    border-left: 6px #f93a37 solid !important;
    color: #000 !important;
}
.chatbot-toast .Toastify__close-button > svg {
    fill: #cecece !important; 
}
.chatbot-toast .Toastify__toast-body {
    align-items: start !important;
}
.chatbot-toast .Toastify__toast-body svg {
    margin-top: 1px !important;
    width: 19px !important;
    height: 19px !important;
}

.chat-response-icons {
    margin-top: 20px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}
.chat-response-icon {
    border: none;
    outline: none;
    background: none;
}
.chatbot-profile-logo {
    width: 40px;
    height: 35px;
}


.chat-response-refrences {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 13px;
}
.chat-response-refrence {
    margin-top: 0px;
    margin-bottom: 0px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.chat-reference-item {
    transition: .3s ease;
    color: #920770;
    text-align: justify;
    font-family: "Poppins400";
    font-size: 11px;
    border-radius: 27px;
    background: rgb(219 219 219 / 12%);
    padding: 11px 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    position: relative; /* For positioning tooltip */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; 
}


.chat-messages-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.1rem;
    height: 2rem;
    border-radius: 50%;
    border: 1px solid #ececec;
}
.chat-messages-icon img {
    width: 25px;
    height: 25px;
}


.chat-nav-container {
    background: #fff;
    position: sticky;
    top: -5px;
    width: 100%;
    min-height: 40px;
    display: flex;
    z-index: 100;
    align-items: center;
    z-index: 1;
}

.react-resizable {
    /* position: absolute !important;
    right: 0;
    top: 0;
    z-index: 1000;
    height: 100vh; */
    margin-top: -15px !important;
    z-index: 98;
}

.viewer-close {
    position: absolute;
    z-index: 99;
    right: 1px;
    top: 1px;
    padding: 7px;
    background: #eeeeee;
    border: unset !important;
    transition: .3s ease;
}

/* Loading */

.loading-circle {
    background: #fff;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}


.comment-text {
    min-height: 120px !important;
    resize: unset !important;
}
.comment-text:focus-within {
    border-color: var(--main-color) !important;
    box-shadow: 0 0 0 2px rgba(146, 7, 112, 0.1);
}
.comment-text:focus {
    border-color: var(--main-color) !important;
    box-shadow: 0 0 0 2px rgba(146, 7, 112, 0.1);
}
.comment-btn {
    border-radius: 5px;
    background: var(--main-color);
    font-family: "Poppins300";
    font-size: 14px;
    padding: 9px 12px;
    cursor: pointer;
    border: unset !important;
    color: #fff;
}

.like-icon {
    cursor: pointer;
}

.arabic-text {
    font-family: "NotoSans", "Poppins300" !important;
    line-height: 28px !important;
}

/* Dark Mode */
.chatbot-background.dark-mode {
    background-color: #000 !important;
}

.chat-response.user-message.dark-mode {
    border-bottom: 1px solid #32343A;
}

.side-chatbot.dark-mode {
    border-right: 1px solid rgba(255, 255, 255, 0.15);
    background: #222;
}

.chatbot-profile-title.dark-mode {
    color: rgba(229, 229, 229, 0.40);
}

.side-footer-chat.dark-mode {
    border-top: 1px solid rgba(255, 255, 255, 0.10);
}

.side-footer-chat.dark-mode svg path, .chat-features.dark-mode svg path,
.chats.dark-mode svg path, .theme-switch.dark-mode svg path, 
.chat-show-sidebar-btn.dark-mode svg path, .chat-hide-sidebar-btn.dark-mode svg path {
    fill: #fff !important;
}

.chat-language.dark-mode svg path {
    stroke: #fff !important;
}

.chat-language.dark-mode::after {
    background: #fff;
}

.side-footer-chat.dark-mode .chat-tool-title, .chat-features.dark-mode .chat-feature-title,
.chat-features.dark-mode .chat-feature-para, .chats.dark-mode .chat-message-title,
.cahabot-profile-name.dark-mode, .chatbot-container.dark-mode .chat-title,
.chatbot-container.dark-mode .chat-para, .chat-link.dark-mode h1,
.chat-language.dark-mode h1, .chat-response.dark-mode .chat-response-para,
.chat-message.dark-mode .chat-message-para, .user-message.dark-mode p {
    color: #fff !important;
}

.chat-link.dark-mode svg path {
    fill: var(--main-color) !important;
}

.chat-input-btn.dark-mode {
    border: 1px solid var(--main-color) !important;
    background: linear-gradient(92deg, rgba(146, 7, 112, 0.70) -16.64%, rgba(146, 7, 112, 0.80) 117.28%) !important;    
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05) !important;
}

.chat-input-container.dark-mode {
    border: 1px solid rgba(229, 229, 229, 0.30);
    background: rgba(255, 255, 255, 0.05) !important;
    backdrop-filter: blur(16px) !important;
}
.chat-input.dark-mode {
    color: #fff;
}
.chat-input.dark-mode::placeholder {
    color: rgba(229, 229, 229, 0.30);
}

.new-chat.dark-mode {
    border: 1px solid rgba(146, 7, 112, 0.80) !important;
    background: linear-gradient(92deg, rgba(146, 7, 112, 0.70) -16.64%, rgba(146, 7, 112, 0.80) 117.28%) !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05) !important;
}


.chat-btns.dark-mode .chat-btn {
    color: #fff;
    border: 1px solid #920770;
    background: transparent;
}
.chat-btns.dark-mode .chat-btn:hover {
    background: linear-gradient(92deg, rgba(146, 7, 112, 0.70) -16.64%, rgba(146, 7, 112, 0.80) 117.28%);
}
.chat-btns.dark-mode .chat-btn-active {
    border: unset;
    background: linear-gradient(92deg, rgba(146, 7, 112, 0.70) -16.64%, rgba(146, 7, 112, 0.80) 117.28%);
}
.chatbot-toast.chatbot-toast-dark {
    background: #2f2f2f !important;
    color: #fff !important;
}
.chatbot-toast.chatbot-toast-dark .Toastify__close-button > svg {
    fill: #fff !important; 
}


.chatbot-copy-toast {
    background: #2f2f2f !important;
    color: #fff !important;
}

.chat-nav-container.dark-mode {
    background: #000;
}

.chat-reference-item.dark-mode {
    color: var(--White, #FFF);
    background: rgba(146, 7, 112, 0.19);
}

.viewer-close.dark-mode {
    background: #292929;
}
.viewer-close.dark-mode svg {
    fill: #eeeeee;
}

.chat-response.dark-mode .chat-messages-icon {
    border: 1px solid #424242;
}

/* Pdf Style */

.pdf-page {
    cursor: pointer;
    transition: transform 0.3s ease;
}

.pdf-page.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 98vw;
    height: 98vh;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1.1);
    cursor: unset;
}

.pdf-page {
    position: relative;
}

.pdf-page.blur {
    /* position: relative; */
    border-left: 18px solid var(--main-color);
    border-right: 18px solid var(--main-color);
    border-bottom: 15px solid var(--main-color);
    border-top: 15px solid var(--main-color);
    border-radius: 5px;
}
.pdf-page.blur::after {
    position: absolute;
    width: 25px;
    height: 23px;
    content: "";
    top: 5px;
    left: -14px;
    /* transform: translateY(-50%);
    border-radius: 50%; */
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: var(--main-color);
    background-image: url("../../assets/‏‏refrence-white.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 10px 10px;
}
.pdf-page.blur.full-screen {
    border: unset !important;
}
.react-pdf__Page {
    position: relative;
    height: 95%;
    margin: auto;
}
.pdf-page.full-screen .react-pdf__Page::before {
    position: absolute;
    content: "";
    font-size: 18px;
    width: 40px;
    height: 40px;
    right: 10px;
    top: 10px;
    background: var(--main-color);
    background-image: url("../../assets/images/close.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25px 20px; 
    z-index: 1111111;
    cursor: pointer;
}
.pdf-page.blur.full-screen .react-pdf__Page::before {
    /* position: absolute;
    padding: 10px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    content: "";
    font-family: "Poppins300";
    color: #fff;
    font-size: 18px;
    width: 40px;
    height: 40px;
    bottom: 30px;
    /* left: 50%; */
    /* right: -10px;
    transform: translateY(-50%);  */
    /* border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; */
    /* background: var(--main-color);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px 16px; 
    z-index: 1111111;*/
}


/* Loading Skelton */
.loading-indicator {
    padding-top: 10px;
}

.loading-indicator .react-loading-skeleton {
    --base-color: #ccc !important;
    --highlight-color:  #989898 !important;
    border-radius: 2px !important;
}

.loading-indicator.dark-mode .react-loading-skeleton {
    --base-color: #eeeeee23 !important;
    --highlight-color:  #ffffff00 !important;
}

/* Resizable Style */
.first-resizable .sidebar-chatbot {
    transition: .5s ease;
    position: absolute;
    width: 320px !important;
    left: -100%;
    z-index: 102;
}
.first-resizable .chat-input-send-container {
    width: 90%;
    margin: 0 auto;
    display: block;
    position: relative;
}
.first-resizable .chat-input-container {
    width: 100%;
}
.first-resizable .chat-container { 
    width: 88%;
}
.first-resizable .chatbot-scroll {
    width: 95%;
}
.first-resizable .chat-container {
    margin-left: unset !important;
    margin: 0 auto !important;
}
.first-resizable .chat-show-sidebar-btn {
    display: block;
}
.first-resizable .chat-hide-sidebar-btn {
    display: block;
}
.first-resizable .chat-title {
    text-align: center;
}
.first-resizable .chat-para {
    text-align: center;
}
.first-resizable .chat-btns {
    justify-content: center;
}

.second-resizable .react-resizable {
    width: 100% !important;
    position: absolute !important;
    left: 0;
    top: 0;
    margin-top: unset !important;
}

@media (max-width: 1400px) {
    .chat-title {
        font-size: 24px;
    }
    .chat-para {
        font-size: 14px;
    }
    .chat-btn  {
        font-size: 13px;
    }
}

@media (max-width: 1200px) {
    .new-chat {
        padding: 10px 17px;
        font-size: 14px;
    }
    .logout-chat-title {
        font-size: 14px;
    }
    .chat-response-para {
        font-size: 15px;
    }
}

@media (max-width: 992px) {
    .sidebar-chatbot {
        transition: .5s ease;
        position: absolute;
        width: 320px !important;
        left: -100%;
        z-index: 102;
    }
    .chat-container, .chat-input-send-container {
        width: 88%;
    }
    .chat-container {
        margin-left: unset !important;
        margin: 0 auto !important;
    }
    .chat-show-sidebar-btn {
        display: block;
    }
    .chat-hide-sidebar-btn {
        display: block;
    }
    .chat-title {
        text-align: center;
    }
    .chat-para {
        text-align: center;
    }
    .chat-btns {
        justify-content: center;
    }
    .chat-messages{
        padding-left: 0px;
    }
}

@media (max-width: 768px) {
    .chat-btn {
        font-size: 12px;
        padding: 10px 16px;
    }
    .chat-btn:hover {
        padding: 10px 16px;
    }
    .react-resizable {
        width: 100% !important;
        position: absolute !important;
        left: 0;
        top: 0;
        margin-top: unset !important;
    }
}

@media (max-width: 667px) {
    .chat-btn {
        font-size: 12px;
        padding: 10px 16px;
    }
}

@media (max-width: 500px) {
    .theme-switch svg:first-child {
        width: 13px !important;
        height: 13px !important;
    }
    .theme-switch svg:last-child {
        width: 18px !important;
        height: 18px !important;
        margin-left: -9px;
    }
    .theme-switch .form-switch .form-check-input {
        margin-top: 5px !important;
        width: 32px !important;
        height: 14px !important;
    }
    .chat-language h1 {
        font-size: 12px;
    }
    .chat-language svg {
        width: 15px;
        height: 15px;
    }
    .chat-link h1 {
        font-size: 13px;
    }
    .chat-language {
        margin-right: 26px;
    }
    .chat-language.dark-mode::after {
        right: -13px;
    }
    .chat-link h1 {
        font-size: 10px;
    } 

}



.react-resizable .react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain !important;
    transform: scale(1.1) !important;
}

.react-resizable .react-pdf__Document {
    height: 100% !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}


.pdf-page.full-screen .react-pdf__Page__canvas {
    transform: unset !important;
}



/* Exit Loading */
.exit-loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11111111111111111;
}

.exit-loading-container .circule-progress {
    color: var(--main-color) !important;
}

/* CSS Styling */
.model-selector {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 10px;
    margin-left: 0px;
    transition: .3s;
}

.model-label {
    font-weight: bold;
    color: #333;
}

.model-dropdown {
    padding:0;
    border: none;
    border-radius: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 10px;
    font-size: 14px;
    cursor: pointer;
}

.model-dropdown:focus {
    outline: none;
}

.dark-mode .model-label,
.dark-mode .model-dropdown {
    color: #f0f0f0;
    border-color: none;
    background-color: transparent;
}

/* Optional styling for hover and active state */
.model-dropdown:hover {
    border-color: none;
}

.model-dropdown option {
    padding: 5px;
    color: #333;
}

.dark-mode .model-dropdown option {
    background-color: #333;
    color: #f0f0f0;
}

.model-dropdown:hover{
    background-color: #f7f7f7;
    transition: .3s;
}

.dark-mode .model-dropdown:hover {
    background-color: #161616;
}

.chat-nav .css-6hp17o-MuiList-root-MuiMenu-list {
    padding-left: 10px !important;
    padding-right: 10px !important;
}


.model-list-item {
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 300px !important;
}

.model-list-item-content {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}
.model-list-item-text {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
}

.model-list-item-title {
    font-family: "Poppins400";
}
.model-list-item-para {
    font-size: 11px;
    font-family: "Poppins300";
    margin-top: 1px;
    color: #747474;
}

.model-list-item-icon {
    border-radius: 50%;
    width: 27px;
    height: 27px;
    background: #4242423b;
    display: flex;
    justify-content: center;
    align-items: center;
}

.model-list-item.dark-mode  .model-list-item-para {
    color: #969696;
}

.model-list-item.dark-mode .model-list-item-icon {
    background: #424242;
}


/* Table Style */
.markdown-table-wrapper {
    margin-bottom: 15px !important;
    overflow: auto !important;
    max-width: 92% !important;
}
.markdown-table-wrapper table {
    margin: 15px 0 !important;
    font-size: 14px !important;
    width: 100% !important;
}
.markdown-table-wrapper tbody, 
.markdown-table-wrapper td, 
.markdown-table-wrapper tfoot, 
.markdown-table-wrapper th, 
.markdown-table-wrapper thead, 
.markdown-table-wrapper tr {
    padding: 10px !important;
    border: 1px solid #000 !important;
    text-align: center !important;
}

.markdown-table-wrapper.dark-mode tbody, 
.markdown-table-wrapper.dark-mode td, 
.markdown-table-wrapper.dark-mode tfoot, 
.markdown-table-wrapper.dark-mode th, 
.markdown-table-wrapper.dark-mode thead, 
.markdown-table-wrapper.dark-mode tr  {
    border: 1px solid #fff !important;
}

@media (min-width: 1400px) {
    .chatbot-scroll table {
        font-size: 14px !important;
    }
}
@media (max-width: 1400px) {
    .chatbot-scroll table {
        font-size: 12px !important;
    }
}

/* End Table Style */