
.App {
  max-width: 100% !important;
  overflow: clip !important;
  /* position: relative; */
}



.App .Toastify__progress-bar--success {
  background: var(--main-color) !important;
}
.App .Toastify__toast-icon svg {
  fill: var(--main-color) !important;
}

input:focus-visible {
  outline: 1px solid #bec5c8; 
}

textarea:focus-visible {
  outline: 1px solid #bec5c8; 
}

.PhoneInputInput:focus-visible {
  outline: unset !important;
}
.disable-btn {
  pointer-events: none;
}
.disable-btn .add-btn-title {
  color: #bec5c8 !important ;
}
.disable-btn svg {
  fill: #bec5c8 !important
}
.disable-btn svg path {
  fill: #bec5c8 !important
}







